<template>
  <v-dialog
    :value="show"
    persistent
    width="500"
    @click:outside="handleClickOutside"
    @keydown="handleKeydown"
  >
    <v-card :loading="loading">
      <v-form ref="form" v-model="form.valid" @submit.prevent="submit">
        <v-card-title>Benutzer einladen</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                clearable
                required
                clear-icon="$delete"
                autocomplete="off"
                class="validation"
                :counter="100"
                label="Name"
                v-model="showName"
                :rules="form.rules.showName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                clearable
                required
                clear-icon="$delete"
                autocomplete="off"
                class="validation"
                :counter="100"
                label="E-Mail-Adresse"
                v-model="email"
                :rules="form.rules.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="error">
            <v-col cols="12"> <strong>Fehler:</strong> {{ error }} </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <temeno-circle-button
            action
            title="abbrechen"
            @click="handleClickCancelButton"
          >
            $close
          </temeno-circle-button>

          <temeno-circle-button
            action
            title="Benutzer einladen"
            type="submit"
            :disabled="!form.valid"
            :loading="loading"
          >
            {{ !form.valid ? '$approve_action_disabled' : '$approve' }}
          </temeno-circle-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";
import { isEmail } from "@/functions";

export default {
  name: "RegistrationDialog",
  components: {
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        rules: {
          showName: [
            (v) => (v && v.length > 0) || this.$t("validation.mandatory"),
          ],
          email: [(v) => isEmail(v) || this.$t("validation.email")],
        },
        valid: false,
      },
      email: null,
      showName: null,
      loading: false,
      error: false,
    };
  },
  methods: {
    cancel() {
      if (!this.loading) {
        this.reset();
        this.$emit("cancel");
      }
    },
    handleClickCancelButton() {
      this.cancel();
    },
    handleClickOutside() {
      this.cancel();
    },
    handleKeydown(key) {
      if (key.code === "Escape") {
        // treat Esc like click outside
        this.handleClickOutside();
      }
    },
    reset() {
      this.error = false;
      this.email = null;
      this.showName = null;
      this.$refs.form.reset();
    },
    submit() {
      this.loading = true;
      this.error = false;
      const { email, showName } = this;
      const createUserRequest = {
        email,
        showName,
      };
      api
        .createUser(createUserRequest)
        .then(() => {
          this.reset();
          this.$emit("submit");
          this.$store.dispatch(
            "snackbar/addMail",
            "Die Einladung wurde erfolgreich gesendet."
          );
        })
        .catch((error) => {
          const response = error.response;
          if (response && response.status === 409) {
            this.error = this.$t("errors.username_exists");
          } else {
            console.error(error);
            this.error = this.$t("errors.unknown");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  i18n: {
    messages: {
      de: {
        errors: {
          unknown: "Ein unbekannter Fehler ist aufgetreten.",
          username_exists:
            "Es ist bereits ein Benutzer mit dieser E-Mail-Adresse registriert.",
        },
        validation: {
          email: "Bitte geben Sie eine gültige E-Mail-Adresse an",
          mandatory: "Dies ist ein Pflichtfeld",
        },
      },
    },
  },
};
</script>
